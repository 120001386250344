<!-- Copyright 2022, Common Good Learning Tools LLC -->

<template><div>
	<p><i>REPLACED WITH TEXT IN THE ARTICLES ON SYSTEM USER ROLES AND FRAMEWORK USER ROLES</i></p>
	<h3>Manage User Roles</h3>

<p>Without logging in, any <strong>public</strong> user with a <span v-html="app_name"/> URL link can view, search, pin, create personal comments for their reference, and print <strong>public</strong> frameworks.

	<ul>
		<li>A signed in user with default (no roles assigned) access can do everything a public user can do as well as be part of a comment group if added, export frameworks and view the Framework Update Report.
			<ul>
				<li><strong>NOTE:</strong> Public non-signed in users of Standards Satchel instances can also perform the actions above in each issuing agency’s instance.</li>
			</ul>
		</li>
	</ul>
</p>

<p>With different subscription <a href="https://docs.google.com/document/d/1NyqvckxrpV_7RE-SP_93x08k3jDlhfNtr9ofH0iM23o" target="_blank">plans</a> and goals for using <span v-html="app_name"/>, some organizations leverage roles roles to grant access to their own hosted version of <span v-html="app_name"/> or a shared patch of <span v-html="app_name"/>. 

	<ul>
		<li>Roles provide sets of permissions to the system (<strong>Dedicated Host</strong> plans only) or specific frameworks (<strong>Shared Host</strong> plans) for the purpose of granting users access, review options for viewing private frameworks, and editing rights.</li>
	</ul>




<table class="k-user-roles-admin-table">
<thead>
<tr>
  <th></th>
  <th><em>Shared & Dedicated Host Plans</em></th>
  <th><em>Dedicated Host Plans Only</em></th>
</tr>
</thead>

<tbody>
<tr>
  <td><strong>Role</strong></td>
  <td><span v-html="link('roles_framework', 'Framework Specific')"></span></td>
  <td><span v-html="link('roles_system', 'System (All Frameworks)')"></span></td>
</tr>

<tr>
  <td><strong>Reviewer</strong><br>(often granted to leadership or specific collaborators added to Comment Groups)</td>
  <td>Default permissions plus visibility to assigned <strong>private</strong> frameworks</td>
  <td>Default permissions plus visibility to all <strong>private</strong> frameworks</td>
</tr>

<tr>
  <td><strong>Editor</strong><br>(often granted to decision makers/leaders for a framework or the system)</td>
  <td>Reviewer permissions plus <strong>edit</strong> and <strong>create</strong> permissions for assigned framework(s)</td>
  <td>Reviewer permissions plus <strong>editing</strong> and <strong>create</strong> permissions for any frameworks</td>
</tr>

<tr>
  <td><strong>Admin</strong><br>(often granted to the Satchel lead managing the users & process of framework review)</td>
  <td>Editor permissions plus <strong>user management</strong> options for assigned frameworks</td>
  <td>Editor permissions plus <strong>user management</strong> options for the system and/or assigned frameworks.</td>
</tr>
</tbody>
</table>



</p>

<p>Ideally, each Framework / System Admin would outline a process for framework review. This may include:

	<ul>
		<li>Establishing a naming convention for frameworks (Editor / Admin)
			<ul>
				<li>Example - Subject name (year adopted or implemented)
					<ul>
						<li>English Language Arts (2019)</li>
					</ul>
				</li>
			</ul>	
		</li>
		<li>Creating categories (System Admin) or using adoption status to identify the stage of the life cycle the framework is in (Editor / Admin)
			<ul>
				<li>Categories can help bundle frameworks by their life cycle: Current - In Review - No Longer in Use or Current - Previous - Proposed
					<ul>
						<li>Georgia’s Standards - Current Versions</li>
						<li>Georgia’s Standards - Previous Versions</li>
					</ul>
				</li>
				<li>Categories can also help group frameworks for private or public viewing that may be used in reference or association to public frameworks
					<ul>
						<li>State-funded Course Guide</li>
						<li>Other Frameworks: WIDA, AP, ACT</li>
					</ul>
				</li>
			</ul>
		</li>
		<li>Setting a <strong>retired date</strong> for a framework that will be deprecated (Editor / Admin)</li>
		<li>Duplicating an existing framework and/or creating a sandbox when a framework needs to be revised (Editor / Admin)</li>
		<li>Creating Comment Groups and verifying the access of needed members (Reviewer, Editor / Admin)</li>
		<li>Determining who will approve suggested edits (Editor / Admin)</li>
		<li>Stamping archives during phases of the revision if needed (Editor / Admin)</li>
		<li>Setting an <strong>implementation date</strong> for a public framework in draft or proposal (Editor / Admin)</li>
		<li>Deciding if a final review (Reviewer, Editor, or Admin) or step takes place for an adoption status to be updated (Editor / Admin)</li>
	</ul>
</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
.k-user-roles-admin-table {
	width: 100%;
	border-collapse: collapse;
	th, td {
		border: 1px solid #000;
		padding: 8px;
		text-align: center;
	}
	th {
		background-color: #000;
		color: #fff;
	}
}
</style>
